import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
import axios from "axios";

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        isLoggedIn: !!localStorage.getItem("token"),
        userType: localStorage.getItem("userType"),
        role: localStorage.getItem("userType"),
        positionId: localStorage.getItem("positionId"),

        userData: {},
        fullData: {},
        email: "",
        menus: [],
        initial: "",
        appLoading: false,
        exceptPos: {},
        currentPage: null,
        date: "",
        status: "",
        travelMode: "",
        assignee:[],
    },
    mutations: {
        loginUser(state, payload) {
            localStorage.setItem("token", payload);
            state.isLoggedIn = true;
            // if (state.userType == 'Admin') router.push('/admin')
            // else router.push('/dashboard')

            if (state.initial) {
                router.push(state.initial);
            } else router.push("/");
            // router.push("/");
        },
        logoutUser(state) {
            state.userType = null;
            state.isLoggedIn = false;
            state.userData = {};
            state.initial = "";

            axios({
                method: "GET",
                url: "/employee/logout",
                headers: {
                    "x-auth-token": localStorage.getItem("token")
                }
            }).then(response => {
                if (response.data) {
                    state.userType = null
                    state.isLoggedIn = false
                    state.userData = {}
                    state.initial = "";
                    localStorage.removeItem("userType");
                    localStorage.removeItem("userid");
                    localStorage.removeItem('departmentId')
                    localStorage.removeItem("token");
                    localStorage.removeItem("positionId")
                    router.push("/login");
                }
            })
        },
        menu(state, item) {
            state.menus = item;
            // console.log("St menu=", item);
            if (item.length > 0) {
                if (item[0]._id !== "60ed5bd072b4ca543c57424d") {
                    if (item[0].submenus.length > 0) {
                        if (item.length > 0) state.initial = item[0].submenus[0].route;
                        else state.initial = "/";
                    } else {
                        state.initial = "/";
                    }
                } else {
                    state.initial = "/";
                }
            } else {
                state.initial = "/";
            }
        },
        userData(state, payload) {
            state.userData = payload;
            state.email = state.userData.email;
        },
        fullData(state, payload) {
            state.fullData = payload;
            state.exceptPos = state.fullData.data.positionid.filter(
                (x) => x._id != state.fullData.position
            );
            localStorage.setItem("userid", state.fullData.data._id);
            if (state.fullData.departmentId) {
                localStorage.setItem('departmentId', state.fullData.departmentId)
                console.log("Current Dept", state.fullData.departmentId);
            }
        },
        assignee(state, payload) {
            state.assignee = payload;
        },
        userType(state, payload) {
            localStorage.setItem("userType", payload);
            state.userType = payload;
        },
        role(state, item) {
            state.role = item
        },
        positionId(state, item) {
            state.positionId = item
        },
        changeDate(state, item) {
            state.date = item
        },
        changeStatus(state, item) {
            state.status = item
        },
        changeCurrentPage(state, item) {
            state.currentPage = item
        },
        changeCurrentPageTeam(state, item) {
            state.currentPage = item
        },
        changeTravel(state, item) {
            state.travelMode = item
        },
        changeTravelStatus(state, item) {
            state.status = item
        },
        changeTravelDate(state, item) {
            state.date = item
        },
        appLoading(state, payload) {
            state.appLoading = payload;
        },
        sessionOut(state) {
            localStorage.removeItem("token");
            state.isLoggedIn = false;
            router.push("/login");
        },
    },
});